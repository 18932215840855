<template>
    <main>
        <swiper :options="swiperOption" ref="mySwiper" class="swiper-container">
            <swiper-slide v-for="(img, index) in images" :key="img.title" :class="`page${index}`">
                <div class="img-container">
                    <img :src="img.url" :alt="img.title">
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>

            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
    </main>
</template>

<script>
    import 'swiper/dist/css/swiper.css'
    import {swiper, swiperSlide} from 'vue-awesome-swiper'
    export default {
        name: "MCollege",
        components: {
            swiper,
            swiperSlide,
        },
        data() {
            return {
                // swiper选项
                swiperOption: {
                    pagination: {
                        el: '.swiper-pagination',
                        autoHeight: true
                    },
                    loop: true,
                    autoplay: true,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                },
                images: [
                    {
                        title: '财商导师学院',
                        url: 'http://cssn.lexiangwx.com/png/R354726302198173696.png'
                    },
                    {
                        title: '财商少年学堂',
                        url: 'http://cssn.lexiangwx.com/png/R354726381596348416.png'
                    },
                    {
                        title: '父母课堂',
                        url: 'http://cssn.lexiangwx.com/png/R354726235601014784.png'
                    },
                ]
            }
        },
        metaInfo: {
            title: '财商少年 FQChildren',
            meta: [
                {
                    name: 'keyWords',
                    content: '财商导师学院,财商少年学堂,父母课堂,财商学院,财商少年'
                },
                {
                    name: 'description',
                    content: '财商学院拥有实战经验丰富的强大财商导师团队，为孩子和家庭打造终身学习成长平台，为父母成长配套课程。'
                }
            ]
        }
    }
</script>

<style lang="scss" scoped>
    .swiper-container {
        img {
            width: 100%;
        }
    }

    // 添加背景颜色, 使页面更好看
    .page0 {
        background: #03b4ee;
        height: 201.2vw;
    }
    .page1 {
        background: #95c51a;
        height: 201.2vw;
    }
    .page2 {
        background: #e40272;
        height: 201.2vw;
    }

    // 样式穿透-左右切换按钮
    ::v-deep .swiper-button-prev,
    ::v-deep .swiper-button-next{
        height: 100%;
        width: 100px;
        top: 0;
        margin: 0;
        background-size: 40px;
        opacity: 0.7;
        outline: none;
    }

    ::v-deep .swiper-button-prev {
        left: 0;
        background-image: url("~@/assets/img/m/icon_left.png");
    }
    ::v-deep .swiper-button-next {
        right: 0;
        background-image: url("~@/assets/img/m/icon_right.png");
    }

</style>
